import React, { useEffect } from "react";
import MobileView from "./component/mobileView/Index";
import { useState } from "react";
import { Notifications } from "react-push-notification";
import { getToken } from "@firebase/messaging";
import { messaging } from "./firebase";
import "./App.css";

const App = () => {
  const [token, setToken] = useState("");
  const [parentCurrentUserType, setParentCurrentUserType] = useState(+localStorage.getItem("userType") || 0);
  const isMobileDevice = () => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);
    return isMobileDevice;
  };

  const requestPermission = async () => {
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BBCPDwUEz-MJhJ8VJRBtFFTl5xM-EKOhPdyqkd5EB0Cn5OnNHJ0CrEcGig2sSJ2jqK6mHfyS7fLmU-EW99CW1-8",
      });
  
      if (token) {
        setToken(token);
        console.log("Token: ", token);
      } else {
        console.log("No token received.");
      }
    } catch (error) {
      console.error("Failed to get token:", error);
      // Handle rejection or error case without crashing
    }
  };

  useEffect(() => {
    requestPermission();
  }, []);

  const driverId = localStorage.getItem("userId");
  const userType = +localStorage.getItem("userType")

  useEffect(() =>{
    if(userType && driverId){
      if(window.ReactNativeWebView) { 
        window.ReactNativeWebView.postMessage(JSON.stringify({userType, driverId, type: "TOKEN"}))
      }
    }
  },[driverId, userType])

  return (
    <div>
      <Notifications />
      {isMobileDevice() ? (
        <MobileView Notifications={Notifications} setParentCurrentUserType={setParentCurrentUserType} isDesktopView={false} token={token} />
      ) : (
        <div className="desktopWrapper">
          <MobileView Notifications={Notifications} setParentCurrentUserType={setParentCurrentUserType} isDesktopView={true} token={token} />
        </div>
      )}
    </div>
  );
};

export default App;
